import React from "react";
import { Link, navigate } from "gatsby"
import i18n from '../../i18n/i18n';
import { connect } from 'react-redux';
import '../../assets/css/index.css';
import { Button, Fab, Drawer } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import background from './images/cover1.jpg';
import farmerEnumeration from './images/farmerEnumeration.jpg';
import fieldManager from './images/fieldManager.jpg';
import fpm from './images/farmerProductionManagement.jpg';
import financialServices from './images/financialServices.jpg';

const styles = theme => ({
    butonBar: {
        paddingRight: '20px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    header: {
        // backgroundImage: `url(${background})`,
        height: '150px',
        textAlign: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    listItem: {
        textAlign: 'center',
        position: 'relative',
        width: '250px',
        padding: '0 15px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '250px',
        textAlign: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% auto',
        margin: '20px',
        color: 'white'
    },
    farm_registry: {
        backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),url(${farmerEnumeration})`,
    },
    field_manager: {
        backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),url(${fieldManager})`,
    },
    farmer_production_management: {
        backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),url(${fpm})`,
    },
    financial_services: {
        backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),url(${financialServices})`,
    },

    link: {
        color: 'white',
        border: '1px solid white',
        padding: '3px 15px',
        "&:hover": {
            backgroundColor: 'rgb(7, 177, 77, 0.42)'
        }
    },
    fab: {
        textAlign: 'center',
        padding: '20px 0'
    }
});

class FMLanding extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            lang: this.props.lang,
            countryCode: ''
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            lang: nextProps.lang
        })
    }

    async componentDidMount() {
        let user = JSON.parse(localStorage.getItem('user'));
        this.setState({ user });
        await fetch('http://ip-api.com/json')
            .then((response) => response.json())
            .then((responseJson) => {
                this.setState({ countryCode: responseJson.countryCode })
            })
            .catch((error) => {
            });
    }

    render() {
        const { classes } = this.props;

        return (
            <div className="index-page">
                <div className={classes.header}>
                    <div className="index-2">
                        <div className="naslov">
                            <h1>DLS for field manager</h1>
                        </div>
                    </div>
                </div>
                <div className="web-index" >
                    <div className={classNames(classes.listItem, classes.farm_registry)} >
                        <h4>Farmer enumeration</h4>
                        <Link to={'/mobile/field_manager/field_manager#reg'} className={classes.link} >
                            More
                        </Link>
                    </div>
                    <div className={classNames(classes.listItem, classes.field_manager)} >
                        <h4>Field manager</h4>
                        <Link to={'/mobile/field_manager'} className={classes.link}>
                            More
                        </Link>
                    </div>
                    <div className={classNames(classes.listItem, classes.farmer_production_management)} >
                        <h4>Farmer Production Management</h4>
                        <Link to={'/mobile'} state={{ field_manager: true }} className={classes.link}>
                            More
                        </Link>
                    </div>
                    <div className={classNames(classes.listItem, classes.financial_services)} >
                        <h4>Financial services</h4>
                        <Link to={'/mobile/financial_services'} className={classes.link}>
                            More
                        </Link>
                    </div>
                </div>
                {this.state.user && (this.state.user.id_klijent_grupa === 13 || this.state.user.id_klijent_grupa === 2) && !this.props.location.pathname.includes("dls") &&
                    <div className={classes.fab}>
                        <Fab
                            variant="extended"
                            primary='true'
                            className={'blinkButton'}
                            size="small"
                            onClick={() => navigate('/dls')}>
                            {i18n.t('Test your knowledge!')}
                        </Fab>
                    </div>
                }

            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        lang: state.appReducer.lang,
        user: state.appReducer.user
    };
}
function mapDispatchToProps(dispatch) {
    return {
    };
}
export default withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(FMLanding));

